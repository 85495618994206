import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EntityService } from '@http/entity.service';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { PlanInfo } from '@models/plan/plan-info';
import { PlanInfoSerializer } from '@models/plan/plan-info.serializer';
import { Workspace } from '@models/workspace/workspace';
import { WorkspaceSerializer } from '@models/workspace/workspace.serializer';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

export type HealthData = {
  successful_inputs: number;
  failed_inputs: number;
  total_inputs: number;
  total_results_created: number;
};

@Injectable({ providedIn: 'root' })
export class WorkspaceService extends EntityService<Workspace> {
  private _planInfoSerializer = new PlanInfoSerializer();

  /**
   *  Constructor
   */
  constructor(private _http: HttpClient) {
    super(_http, environment.apiURL, 'workspaces/', new WorkspaceSerializer());
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update settings Users Management via API
   */
  public updateUsersManagementViaApi(
    workspace: Workspace
  ): Observable<CptSuccessResponse<null>> {
    return this.httpClient
      .patch<CptSuccessResponse<null>>(
        `${this.url}/${this.resource}users_management_via_api`,
        {
          is_enabled: workspace.usersManagementViaApi,
          max_users: workspace.maxUsersViaApi
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Update settings Auto Resume Queued Runs
   */
  public updateAutoResumeQueuedRuns(
    workspace: Workspace
  ): Observable<CptSuccessResponse<null>> {
    return this.httpClient
      .patch<CptSuccessResponse<null>>(
        `${this.url}/${this.resource}auto_resume_queued_runs`,
        {
          is_enabled: workspace.autoResumeQueuedRuns
        },
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Get health data for the last month for the current workspace
   */
  public readLastMonthHealthData(): Observable<CptSuccessResponse<HealthData>> {
    return this.httpClient
      .get<CptSuccessResponse<HealthData>>(
        `${this.url}/${this.resource}get_last_month_health`,
        this.options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Returns detailed billing info for the current workspace
   */
  public readBillingInfo(): Observable<CptSuccessResponse<PlanInfo>> {
    return this.httpClient
      .get<CptSuccessResponse<PlanInfo>>(
        `${this.url}/${this.resource}billing_info`,
        this.options
      )
      .pipe(
        map((response: CptSuccessResponse<PlanInfo>) => {
          response.content = this._planInfoSerializer.toEntity(
            response.content
          ) as PlanInfo;
          return response;
        }),
        catchError(this.handleError)
      );
  }
}
