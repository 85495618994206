import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CptDropdownModule } from '@captaindatatech/captaindata-angular-lib/dropdown';
import { CptInputTextModule } from '@captaindatatech/captaindata-angular-lib/input-text';
import {
  CptOverlayPanelModule,
  OverlayPanelComponent
} from '@captaindatatech/captaindata-angular-lib/overlay-panel';
import { CptProgressBarModule } from '@captaindatatech/captaindata-angular-lib/progress-bar';
import { CptSearchModule } from '@captaindatatech/captaindata-angular-lib/search';
import { CptSvgIconsModule } from '@captaindatatech/captaindata-angular-lib/svg-icon';
import { CptTooltipModule } from '@captaindatatech/captaindata-angular-lib/tooltip';
import { Nullable } from '@captaindatatech/captaindata-angular-lib/ts-helpers';
import { AbilityModule } from '@casl/angular';
import { environment } from '@env/environment';
import { AdminSpecificationsService } from '@http/admin-specifications.service';
import { KratosService } from '@http/kratos.service';
import { WorkspaceService } from '@http/workspace.service';
import { AdminSpecifications } from '@models/admin-specifications/admin-specifications';
import { CptSuccessResponse } from '@models/captain/success-response/cpt-success-response';
import { SRS } from '@models/srs/srs';
import { User } from '@models/user/user';
import { Workspace } from '@models/workspace/workspace';
import { AppPlanInfoService } from '@services/plan/app-plan-info.service';
import { AppUserService } from '@services/user/app-user.service';
import { AppWorkspaceService } from '@services/workspace/app-workspace.service';
import { SharedCommonModule } from '@shared/common/common.module';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import type { NavbarAppearance } from './navbar.types';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    SharedCommonModule,
    CptOverlayPanelModule,
    CptSvgIconsModule,
    CptSearchModule,
    CptDropdownModule,
    CptInputTextModule,
    CptProgressBarModule,
    CptTooltipModule,
    AbilityModule
  ],
  templateUrl: 'navbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() appearance: NavbarAppearance = 'expanded';

  user: Nullable<User>;
  searchValue: string = '';
  workspace: Nullable<Workspace>;
  workspaces: Nullable<Workspace[]>;
  filteredWorkspaces: Nullable<Workspace[]>;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  headlessbranchOptions: string[] = ['master', 'Branche 1', 'Branche 2'];
  serverlessVersionOptions: string[] = ['master', 'staging', 'develop'];

  planInfo$ = this._appPlanInfoService.planInfo$;

  @ViewChild(OverlayPanelComponent) overlayPanel: OverlayPanelComponent;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _appUserService: AppUserService,
    private _appWorkspaceService: AppWorkspaceService,
    private _workspaceService: WorkspaceService,
    private _adminSpecificationsService: AdminSpecificationsService,
    private _kratosService: KratosService,
    private _appPlanInfoService: AppPlanInfoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ HostBinding
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      navbar: true,
      [`navbar-appearance-${this.appearance}`]: true
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // User subscription
    this._appUserService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: Nullable<User>) => {
        this.user = user;
        this._changeDetectorRef.markForCheck();
      });

    // Workspace subscription
    this._appWorkspaceService.workspace$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((workspace: Nullable<Workspace>) => {
        this.workspace = workspace;
        this._changeDetectorRef.markForCheck();
      });

    // Workspaces subscription
    this._appWorkspaceService.workspaces$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((workspaces: Nullable<Workspace[]>) => {
        this.workspaces = workspaces;
        this.filteredWorkspaces = workspaces;
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Select workspace as current workspace
   *
   * @param workspace
   */
  selectWorkspace(workspace: Workspace) {
    this._appWorkspaceService.workspace = workspace;
    this.overlayPanel?.hide();
  }

  /**
   * Search for workspace from searched value
   */
  onWorkspaceSearch(value: string) {
    let srs: Nullable<SRS<Workspace>> = null;
    if (value) {
      srs = {
        search: [
          { key: 'name', value: value },
          { key: 'permalink', value: value }
        ]
      } as SRS<Workspace>;
    }
    this._workspaceService.list(srs).subscribe({
      next: (response: CptSuccessResponse<Workspace[]>) => {
        this.filteredWorkspaces = response.content;
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  /**
   * Reset data of overlay panel when it hides
   */
  onOverlayPanelHide() {
    this.filteredWorkspaces = this.workspaces;
    this.searchValue = '';
  }

  updateAdminSpecifictions(val: string, field: keyof AdminSpecifications) {
    if (this.user?.adminSpecifications[field] !== val) {
      const prevValue: string = this.user?.adminSpecifications[field] as string;
      this.user!.adminSpecifications[field] = val;
      this._adminSpecificationsService
        .updateField(this.user?.adminSpecifications!, field)
        .subscribe({
          next: (response: CptSuccessResponse<AdminSpecifications>) => {},
          error: () => {
            this.user!.adminSpecifications[field] = prevValue;
          }
        });
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackWorkspacesByFn(index: number, workspace: Workspace): any {
    return workspace.uid || index;
  }

  /**
   * Logout user
   */
  logout() {
    this._kratosService.getLogoutUrl().subscribe({
      next: (response) => {
        window.open(
          encodeURI(`${environment.baseGatewayUrl}${response.logout_url}`),
          '_self'
        );
      },
      error: () => {
        window.open(
          `${environment.selfserviceUrl}/login?return_to=${encodeURI(
            environment.appUrl + '/homepage'
          )}`,
          '_self'
        );
      }
    });
  }
}
