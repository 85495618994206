import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedAccountConfigurationComponent } from '@modules/integration/shared-account-configuration/shared-account-configuration.component';
import { AppTemplateService } from 'src/app/shared/templates/app-template.service';
import { authGuard } from './core/guards/auth.guard';
import { meGuard } from './core/guards/me.guard';
import { LayoutComponent } from './layout/layout.component';
import { PrivateScopeComponent } from './scope/private/private-scope.component';
import { PublicScopeComponent } from './scope/public/public-scope.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'workflows' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'workflows' },

  // Auth routes for authenticated users
  {
    path: '',
    component: PublicScopeComponent,
    children: [
      {
        path: 'integrations/:integrationPermalink/account/:accountUid',
        component: SharedAccountConfigurationComponent,
        data: {
          layout: 'empty'
        }
      }
    ]
  },
  {
    path: '',
    canMatch: [authGuard, meGuard],
    component: PrivateScopeComponent,
    children: [
      {
        path: '',
        component: LayoutComponent,
        data: {
          layout: 'expanded'
        },
        children: [
          {
            path: 'settings',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/settings/settings.module').then(
                (m) => m.SettingsModule
              )
          },
          {
            path: 'actions-library',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/actions-library/actions-library.module').then(
                (m) => m.ActionsLibraryModule
              )
          },
          {
            path: 'templates-library',
            providers: [
              AppTemplateService,
              {
                provide: 'mode',
                useValue: 'route'
              }
            ],

            data: {
              layout: 'expanded'
            },
            loadComponent: () =>
              import('./modules/templates-library/templates-library.route')
          },
          {
            path: 'templates-library/:workflowUid',

            data: {
              layout: 'expanded'
            },
            loadComponent: () => import('./modules/template/template.route')
          },
          {
            path: 'integrations',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/integrations/integrations.module').then(
                (m) => m.IntegrationsModule
              )
          },
          {
            path: 'workflows',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/workflows/workflows.module').then(
                (m) => m.WorkflowsModule
              )
          },
          {
            path: 'profile',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/profile/profile.module').then(
                (m) => m.ProfileModule
              )
          },
          {
            path: 'activity',
            data: {
              layout: 'expanded'
            },
            loadChildren: () =>
              import('./modules/activity/activity.module').then(
                (m) => m.ActivityModule
              )
          }
        ]
      }
    ]
  },
  // TODO: renvoyer vers une page 404 not found
  { path: '**', redirectTo: 'workflows' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
