import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';

export type KratosError = {
  code: number;
  status: string;
  reason: string;
  message: string;
};

@Injectable({ providedIn: 'root' })
export class KratosService {
  constructor(private _http: HttpClient) {}

  /**
   * Check if the user is authenticated in Kratos
   *
   * @returns
   */
  getSessionInformation(): Observable<any> {
    return this._http.get<any>(`${environment.kratosApiUrl}/sessions/whoami`, {
      withCredentials: true
    });
  }

  /**
   * Ask Kratos to log the user out
   * Returns a logout url with a flow
   */
  getLogoutUrl(): Observable<{ logout_url: string }> {
    return this._http.get<{ logout_url: string }>(
      encodeURI(`${environment.kratosApiUrl}/self-service/logout/browser`),
      {
        withCredentials: true
      }
    );
  }
}
